import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageServices from '../services/PageServices'
import useAsync from '../hooks/useAsync'
import { constant } from '../constant/index.constant';

function Blog() {

  const { data } = useAsync(PageServices.getBlogData);
  const [blogData, setblogData] = useState([]);
  const [blogData2, setBlogData2] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 9;

  useEffect(() => {
    // Check if data is available and update form fields
    if (data?.data?.blog) {
      setblogData(data.data.blog || [])// Replace 'description' with the actual key from your API response
      setBlogData2(data.data.blog || [])
    }
  }, [data]);


  useEffect(() => {
    // Check if data is available and update form fields
    if (filteredBlogData) {
      setblogData(filteredBlogData || [])
    }

  }, [searchQuery]);

  useEffect(() => {
    // Check if data is available and update form fields
    if (filteredBlogCatogoryData) {
      setblogData(filteredBlogCatogoryData || [])
    }

  }, [selectedCategory]);

  // Logic to calculate pagination
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogData.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);


  const filteredBlogData = blogData.filter(blog => {
    const blogTitle = blog.blogTitle.toLowerCase();
    return blogTitle.includes(searchQuery.toLowerCase());
  });

  const filteredBlogCatogoryData = blogData.filter(blog => {
    if (selectedCategory === 'All') {
      return true; // Show all blogs if 'All' category is selected
    }
    return blog.category === selectedCategory;
  });

  const handleSearchChange = event => {
    setblogData(blogData2)
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (e, category) => {
    e.preventDefault()
    setblogData(blogData2)
    setCurrentPage(1)
    setSelectedCategory(category);
  };
  const formatDate = date => {
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };
  return (
   
      <div>
        <section className>
          <div className="banner-sec banner-new-bg">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="banner-content-sec">
                    <h1>Study Abroad <span>Blogs</span></h1>
                    <p>Abroad Insights: News and Tips for Students</p>
                    <div className="hero-search-field position-relative">
                      <span><i className="fa fa-search" /></span>
                      <input type="search" className="form-control" name="search" value={searchQuery}
                        onChange={handleSearchChange} placeholder="What are you looking for?" />
                      <button className="site-btn-2 site-btn">Search</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-us-img blog-banner-img text-center">
                    <img src="assets/img/blog-banner-img.svg" alt='blog banner'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== hero section end ===== */}
        <section className="blog-b-section py-70" >
          <div className="container">
            <div className="blog-tab-scroll mb-5">
              <ul className="blog-tab nav nav-pills" id="blog-b-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="a-blog-tab" data-bs-toggle="pill" data-bs-target="#a-blog" type="button" role="tab" onClick={(e) => { handleCategoryChange(e, 'All') }} aria-controls="a-blog" aria-selected="true">All</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="b-blog-tab" data-bs-toggle="pill" data-bs-target="#b-blog" type="button" role="tab" aria-controls="a-blog" onClick={(e) => { handleCategoryChange(e, 'GMAT') }} aria-selected="false">GMAT</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="c-blog-tab" data-bs-toggle="pill" data-bs-target="#c-blog" type="button" role="tab" aria-controls="a-blog" onClick={(e) => { handleCategoryChange(e, 'IELTS') }} aria-selected="false">IELTS</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="d-blog-tab" data-bs-toggle="pill" data-bs-target="#d-blog" type="button" role="tab" aria-controls="a-blog" onClick={(e) => { handleCategoryChange(e, 'TOEFL') }} aria-selected="false">TOEFL</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="e-blog-tab" data-bs-toggle="pill" data-bs-target="#e-blog" type="button" role="tab" aria-controls="a-blog" onClick={(e) => { handleCategoryChange(e, 'GRE') }} aria-selected="false">GRE</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="f-blog-tab" data-bs-toggle="pill" data-bs-target="#f-blog" type="button" role="tab" aria-controls="a-blog" onClick={(e) => { handleCategoryChange(e, 'PTE') }} aria-selected="false">PTE</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="g-blog-tab" data-bs-toggle="pill" data-bs-target="#g-blog" type="button" role="tab" aria-controls="a-blog" onClick={(e) => { handleCategoryChange(e, 'SAT') }} aria-selected="false">SAT</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="g-blog-tab" data-bs-toggle="pill" data-bs-target="#g-blog" type="button" role="tab" aria-controls="a-blog" onClick={(e) => { handleCategoryChange(e, 'ENGLISH') }} aria-selected="false">SPOKEN ENGLISH</button>
                </li>
              </ul>
            </div>
            <div className="tab-content" >
              <div className="tab-pane fade blog-section-inner active show" aria-labelledby="a-blog-tab">
                <div className="row gy-4">
                  {currentBlogs.map((blog) => (
                    <div key={blog.Slug} className="col-md-6 col-lg-4">
                      <div className="blog-card">
                        <div className="blog-card-img-box">
                          <img src={`${constant.REACT_APP_URL}/uploads/${blog.image}`} alt={blog.image} />
                        </div>
                        <div className="blog-card-content">
                          <ul className="list-unstyled d-flex justify-content-between align-items-center">
                            {/* <li><span><img src="assets/img/user-icon.svg" /></span><span>{blog.createdBy}</span></li> */}
                            <li><span><img alt='calendar icon' src="assets/img/date-icon.svg" /></span><span>{formatDate(blog.createdAt)}</span></li>
                          </ul>
                          <h5><Link to={`/blog-description/${blog.Slug}`}>{blog.blogTitle}</Link></h5>
                          <Link to={`/blog-description/${blog.Slug}`} className="site-btn">Read more</Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
            {blogData.length > blogsPerPage && (
              <nav aria-label="Page navigation example" style={{ marginTop: '30px' }}>
                <ul className="pagination">
                  {[...Array(Math.ceil(blogData.length / blogsPerPage)).keys()].map(number => (
                    <li className={`page-item ${currentPage === number + 1 ? 'active' : ''}`} key={number + 1}>
                      <Link className="page-link" to="#blog-b-section" onClick={() => paginate(number + 1)}>
                        {number + 1}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </div>
        </section>
        {/* ======== become partner section start ===== */}
        <section className="app-banner-section counselling-session-sec">
          <div className="container">
            <div className="app-banner-section-inner app-banner-section-inner-2">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="app-banner-content-left">
                    <h2 className="mb-3">Avail A Complementary Counselling Session</h2>
                    <p className="mb-4">Join thousand of instructors and earn money hassle free!</p>
                    <Link className="site-btn" to="/contact">Contact us</Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="app-banner-content-right text-center">
                    <img src="assets/img/counselling-session.svg" alt="partner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}

export default Blog