import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { testimonialSlider } from '../custom/custom'
import PageServices from '../services/PageServices';


import DocumentMeta from 'react-document-meta';

function SpokenEnglish() {

  const [testimonials, setTestimonial] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getAllfaqData = async (value) => {
    try {
      const response = await PageServices.getAllFaqForFront(value);
      if (response.status === 'success') {
        setFaqData(response.data.faq || [])
      } else {
        console.log('something went wrong');
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getAllTestimonial = async (value) => {
    try {
      const response = await PageServices.getTestimonialByCat(value);
      if (response.status === 'success') {
        setTestimonial(response.data.testimonial || [])
      } else {
        console.log('something went wrong');
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    getAllfaqData("spokenEnglish");
    getAllTestimonial("spokenEnglish");
  }, []);

  const meta = {
    title: "Spoken English",
    description: 'Learn about our company.',
    // Add more meta tags as needed
  };
  return (
    <div>
      <DocumentMeta {...meta} />
      {/* ======== hero section start ===== */}
      <section>
        <div className="banner-sec banner-new-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="banner-content-sec">
                  <h1>Spoken<span> English</span></h1>
                  <p>Chart your course for academic excellence on the world stage with Gateway Abroad. We are not merely a spoken English preparatory center; we are your strategic partner in achieving your international education aspirations. We recognize the potential within you, the ambition to conquer new academic frontiers. Our unwavering commitment is to equip you with the knowledge, refined communication skills, and unwavering confidence to not only excel on standardized tests but to thrive in a global learning environment.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-us-img text-center">
                  <img src="assets/img/spoken-english-img.svg" alt='spoken-english' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="contact-us-section py-70">
        <div className="container">
          <div className="get-in-touch-section">
            <h2 className="heading text-center d-block mb-3">Why Choose Gateway Abroad for Spoken English Classes ?</h2>
            <div className="spoken-english-feature-section pt-4">
              <div className="row gy-3 justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="features-guide-box">
                    <div className="features-guide-left"><img decoding="async" src="assets/img/graduation.png" alt="" /></div>
                    <div className="features-guide-right"> <strong>Accomplished Faculty:</strong> Composed of seasoned educators, with CELTA and TESOL training, our faculty goes beyond traditional methodologies. They ignite your academic passion, cultivate a deep understanding of core concepts, and empower you to excel.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="features-guide-box">
                    <div className="features-guide-left"><img decoding="async" src="assets/img/personalized-learning.png" alt="" /></div>
                    <div className="features-guide-right"> <strong>Personalized Learning Trajectory:</strong> Your goals are distinct, and our approach reflects that. We meticulously craft customized learning plans that cater to your individual needs and learning style.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="features-guide-box">
                    <div className="features-guide-left"><img decoding="async" src="assets/img/consulting.png" alt="" /></div>
                    <div className="features-guide-right"> <strong>Focus on Communication, Not Just Grammar:</strong> We go beyond rote learning and focus on developing your real-world communication skills. You’ll learn to express yourself clearly and confidently in various situations, from social gatherings to professional settings.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="features-guide-box">
                    <div className="features-guide-left"><img decoding="async" src="assets/img/confidence.png" alt="" /></div>
                    <div className="features-guide-right"> <strong>Building Confidence:</strong> Fluency in English is more than just grammar and vocabulary; it’s about confidence. Our interactive classes and supportive environment will help you overcome hesitation and speak English with self-assurance.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="features-guide-box">
                    <div className="features-guide-left"><img decoding="async" src="assets/img/course.png" alt="" /></div>
                    <div className="features-guide-right"> <strong>Beyond the Classroom:</strong> Gateway Abroad goes beyond spoken English classes. We organize workshops, guest lectures, and cultural events to provide you with a well-rounded learning experience and exposure to diverse accents and communication styles.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ======== Our Testimonials section start ===== */}
      <section className="our-testimonials py-70 gmat-testimonials">
        <div className="container">
          <h2 className="heading text-center d-block">What Our Spoken English Prep Achievers Say</h2>
          {testimonials.length == 1 ?
            <div className="our-testimonials-slider-inner single-testmonial" >
              <div className="student-test-box">
                <div className="stundent-content">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6>{testimonials[0].name}</h6>
                    <ul className="list-unstyled d-flex">
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                      <li><span><i className="fa fa-star" /></span></li>
                    </ul>
                  </div>
                  <p className="descp">{testimonials[0].content.substring(0, 250)}</p>
                  {/* <div className="student-img">
                    <img src={`${constant.REACT_APP_URL}/uploads/${testimonials[0].image}`} />
                  </div> */}
                </div>
                <div className="test-univ-sec">
                  {/* <h5>{testimonials[0].univercity.substring(0, 30)}</h5> */}
                </div>
              </div>
            </div> :
            <Slider {...testimonialSlider} className="our-testimonials-slider">
              {testimonials.map((test) => (
                <div className="our-testimonials-slider-inner" key={test.id}>
                  <div className="student-test-box">
                    <div className="stundent-content">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6>{test.name}</h6>
                        <ul className="list-unstyled d-flex">
                          <li><span><i className="fa fa-star" /></span></li>
                          <li><span><i className="fa fa-star" /></span></li>
                          <li><span><i className="fa fa-star" /></span></li>
                          <li><span><i className="fa fa-star" /></span></li>
                          <li><span><i className="fa fa-star" /></span></li>
                        </ul>
                      </div>
                      <p className="descp">{test.content.substring(0, 250)}</p>
                      {/* <div className="student-img">
                    <img src={`${constant.REACT_APP_URL}/uploads/${test.image}`} />
                  </div> */}
                    </div>
                    <div className="test-univ-sec">
                      {/* <h5>{test.univercity.substring(0, 30)}</h5> */}
                    </div>
                  </div>
                </div>
              ))}

            </Slider>
          }
        </div>
      </section>
      {/* ======== Our Testimonials section start ===== */}
      <section className="english-components-section py-70">
        <div className="container">
          <div className="title text-center mb-5">
            <h2 className="heading mb-2">Components of the English Language</h2>
            <p className="descp text-center">The English language can be broken down into several key components that work together to create meaning</p>
          </div>
          <div className="english-components-section-inner">
            <div className="row">
              <div className="col-lg-5">
                <div className="english-components-inner-left text-center">
                  <img src="assets/img/english-components-img.svg" alt='english-components' />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="english-components-inner-right">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-grammar-tab" data-bs-toggle="pill" data-bs-target="#pills-grammar" type="button" role="tab" aria-controls="pills-grammar" aria-selected="true">Grammar</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-phonology-tab" data-bs-toggle="pill" data-bs-target="#pills-phonology" type="button" role="tab" aria-controls="pills-phonology" aria-selected="false">Phonology</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-vocabulary-tab" data-bs-toggle="pill" data-bs-target="#pills-vocabulary" type="button" role="tab" aria-controls="pills-vocabulary" aria-selected="false">Vocabulary</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-semantics-tab" data-bs-toggle="pill" data-bs-target="#pills-semantics" type="button" role="tab" aria-controls="pills-semantics" aria-selected="false">Semantics</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-pragmatics-tab" data-bs-toggle="pill" data-bs-target="#pills-pragmatics" type="button" role="tab" aria-controls="pills-pragmatics" aria-selected="false">Pragmatics</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-grammar" role="tabpanel" aria-labelledby="pills-grammar-tab">
                      <p className="descp mb-2">This is the system of rules governing how words are formed, how sentences are structured, and how meaning is conveyed.  Here are some important aspects of grammar:</p>
                      <p className="descp mb-2"><strong>Morphology:</strong>  This deals with the structure of words, including prefixes, suffixes, and how words are formed (e.g., adding “-ing” to a verb to make it present participle)</p>
                      <p className="descp mb-2"><strong>Syntax:</strong>  This governs how words are arranged in phrases, clauses, and sentences to create meaning. (e.g., “The dog chased the cat” vs. “The cat chased the dog”)</p>
                      <p className="descp mb-2"><strong>Tense: </strong> This indicates the time frame of an action or event (e.g., past, present, future)</p>
                      <p className="descp mb-2"><strong>Aspect:</strong> This shows whether an action is ongoing, completed, or has a specific beginning and end. (e.g., “He is eating” vs. “He has eaten”)</p>
                    </div>
                    <div className="tab-pane fade" id="pills-phonology" role="tabpanel" aria-labelledby="pills-phonology-tab">
                      <p className="descp mb-2">This is the study of the sound system of a language, including the sounds themselves, the rules for how sounds can be combined, and how these sounds are used to distinguish meaning. </p>
                      <p className="descp mb-2"><strong>Phonemes:</strong> These are the basic units of sound in a language that can differentiate words. (e.g., “p” and “b” are separate phonemes in English because “pin” and “bin” have different meanings)</p>
                      <p className="descp mb-2"><strong>Stress:</strong> This refers to the emphasis placed on certain syllables in a word or sentence.</p>
                      <p className="descp mb-2"><strong>Intonation:</strong> This is the variation in pitch of your voice when speaking, which can convey different meanings or emotions.</p>
                    </div>
                    <div className="tab-pane fade" id="pills-vocabulary" role="tabpanel" aria-labelledby="pills-vocabulary-tab">
                      <p className="descp mb-2">This refers to the words that make up a language.  English vocabulary is vast and constantly evolving, with words borrowed from other languages and new words being created all the time. </p>
                    </div>
                    <div className="tab-pane fade" id="pills-semantics" role="tabpanel" aria-labelledby="pills-semantics-tab">
                      <p className="descp mb-2">This is the study of meaning in language, including how words and sentences convey meaning and how meaning is interpreted in context.</p>
                      <p className="descp mb-2"><strong>Denotation:</strong> This is the literal meaning of a word</p>
                      <p className="descp mb-2"><strong>Connotation:</strong> This is the emotional or associative meaning of a word, which can go beyond its literal definition.</p>
                    </div>
                    <div className="tab-pane fade" id="pills-pragmatics" role="tabpanel" aria-labelledby="pills-pragmatics-tab">
                      <p className="descp mb-2">This is the study of how language is used in context to achieve communication goals. It considers factors like the speaker, listener, and the situation to understand the intended meaning. </p>
                      <p className="descp mb-2"><strong>Speech acts:</strong> These are actions performed through language, such as requesting, apologizing, or promising.</p>
                      <p className="descp mb-2"><strong>Presupposition:</strong> This refers to what the speaker assumes the listener already knows.</p>
                      <p className="descp mb-2"><strong>Implicature:</strong> This is the meaning that is conveyed indirectly, often through context or tone of voice.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======== FAQs section start ===== */}
      <section className="faq-section py-70 mb-0">
        <div className="container">
          <div className="title text-center mb-5">
            <h2 className="heading mb-2">Frequently asked questions</h2>
            <p className="descp text-center">Can't find the answer you are looking for?</p>
          </div>
          <div className="faq-section-container">
            <div className="accordion" id="accordionExample">
              {faqData.map((f, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                      type="button"
                      onClick={() => toggleAccordion(index)}
                      aria-expanded={activeIndex === index ? 'true' : 'false'}
                      aria-controls={`collapse${index}`}
                    >
                      {f.title}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {f.content}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* ======== FAQs section end ===== */}


      <section className="pricing-plan-section py-70 linear-bg spoken-english">
        <div className="container">
          <div className="price-title">
            <h2 className="heading mb-2">Plans &amp; Pricing</h2>
            <p className="descp">We are accepting PayPal, Paytm, PhonePe and Debit &amp; Credit Card</p>
          </div>
          <div className="pricing-plan-section-inner">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="pricing-card">
                  {/* <h4 className="price">$19 <span>/month</span></h4> */}
                  <h5 className="plan-name">Classroom training</h5>
                  <div className="plan-offer-list">
                    <ul className="list-unstyled">
                      <li>Tired of feeling tongue-tied? Gateway Abroad's interactive English test preparation classes are designed to transform you from shy to shine!  Our engaging classroom environment provides a supportive space where you'll gain the confidence and fluency you need to ace your English language exam</li>
                      <li>Don’t just learn English, live it. Our interactive spoken English classes get you talking from day one.</li>
                    </ul>
                  </div>
                  <div className='text-center'>
                    <Link to="#" className="choose-plan-btn site-btn" data-bs-toggle="modal" data-bs-target="#getintouchModel">Choose Plan</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-card">
                  {/* <h4 className="price">$54 <span>/month</span></h4> */}
                  <h5 className="plan-name">Live online training</h5>
                  <div className="plan-offer-list">
                    <ul className="list-unstyled">
                      <li>Can't make it to a physical classroom? No problem! Gateway Abroad's comprehensive online English test preparation classes offer the same exceptional instruction and proven results  - all from the comfort of your own home.</li>
                      <li>Fit fluency into your busy schedule. Gateway Abroad’s online spoken English courses offer flexibility and results.</li>
                    </ul>
                  </div>
                  <div className='text-center'>
                    <Link to="#" className="choose-plan-btn site-btn" data-bs-toggle="modal" data-bs-target="#getintouchModel">Choose Plan</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-card most-popular-card">
                  <div className="most-p-btn-outer">
                    <button className="most-p-btn">Most Popular</button>
                  </div>
                  {/* <h4 className="price">$89 <span>/month</span></h4> */}
                  <h5 className="plan-name">Hybrid</h5>
                  <div className="plan-offer-list">
                    <ul className="list-unstyled">
                      <li>Get   the   best   of   both worlds  with  our  hybrid courses - the flexibility of   online   learning combined   with   the personalized support of in-person instruction!</li>
                      <li>Do you crave the structure and focus of a classroom environment, but also enjoy the flexibility of online learning? Gateway Abroad's hybrid English test preparation classes offer the perfect solution!</li>
                    </ul>
                  </div>
                  <div className='text-center'>
                    <Link to="#" className="choose-plan-btn site-btn" data-bs-toggle="modal" data-bs-target="#getintouchModel">Choose Plan</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======== become partner section start ===== */}
      <section className="app-banner-section counselling-session-sec">
        <div className="container">
          <div className="app-banner-section-inner counselling-session-sec-inner">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="app-banner-content-left">
                  <h2 className="mb-3">Avail A Complementary Counselling Session</h2>
                  <p className="mb-4">Join thousand of instructors and earn money hassle free!</p>
                  <Link className="site-btn" to="/contact">Contact us</Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="app-banner-content-right text-center">
                  <img src="assets/img/counselling-session.svg" alt="partner" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======== become partner section end ===== */}
      {/* ======== footer section end ===== */}
    </div>
  );
}
export default SpokenEnglish